 /* Hide menu on larger screens */
 @media (max-width: 1366.98px) {


     .header nav ul li span,
     .header nav ul li a {
         font-size: 14px;
     }
 }

 @media (min-width: 991.98px) {
     a.nav-link.mboilehidee, .resfilte-filter-dektop {
         display: none;
     }

     .backarrow_chat {
         display: none;
     }
 }

 @media (max-width: 1200.98px) {
     .pxl-menu-close {
         position: absolute;
         top: 0;
         right: 0;
         -webkit-border-radius: 0;
         -khtml-border-radius: 0;
         -moz-border-radius: 0;
         -ms-border-radius: 0;
         -o-border-radius: 0;
         border-radius: 0;
     }
 }

 @media (max-width: 1366.98px) {}

 @media (max-width: 1200.98px) {
     .container, .container-lg, .container-md, .container-sm {
         max-width: 100%;
     }

 }

 @media (max-width: 1024.98px) {}

 @media (min-width: 767.98px) {

     .fitler-cardsadd .form-control {
         display: none;
     }
 }

 @media (max-width: 991.98px) {
     .loginModalMyplo_left {
         display: none;
     }

     .prodcuts_listing_colleft {
         width: 100%;
         margin-bottom: 20px;
         padding-left: 15px;
         padding-right: 15px;
     }

     .prodcuts_listing_colleft .filertcler {
         margin-top: 10px;
         margin-bottom: 10px;
     }

     .prodcuts_listing_colleft .labelName-filter {
         margin-bottom: 0px;
         padding-bottom: 0px !important;
     }

     .prodcuts_listing_colleft .nn_fliterctn {
         padding: 0px !important;
     }

     .prodcuts_listing_colleft .inpydeanrr {
         padding: 20px 15px;
     }

     .prodcuts_listing_colleft .nn_fliterctn {
         margin-bottom: 10px;
     }

     .prodcuts_listing_colleft label {
         margin-bottom: 0px !important;
     }

     .prodcuts_listing_colleft label {
         padding-left: 0px !important;
     }
     .chatrt_msg_footer .nn_chatbtctn {
        padding: 0px;
     }
     .chatrt_msg_footer .nn_chatbtctn {
         max-width: 100%;
         overflow: auto;
     }

     .loginModalMyplo .closicon-login i {
         color: var(--theme-color);
     }

     .chat_flex_con_left.active {
         display: none;
     }

     .loginModalMyplo_Right {
         width: 100%;
     }

     .chat_flex_con_right {
         display: none;
         width: 100%;
     }

     .chat_flex_con_right.active {
         display: block;
     }

     .chat_flex_con_left {
         width: 100%;
     }

     .chat_flex_con {
         flex-wrap: wrap;
     }

     .prodcuts_listing_colright {
         width: 100%;
         padding-bottom: 0px;
         padding-left: 0px;
     }

     .ReactModalPortal .slide-pane__overlay.overlay-after-open .slide-pane_from_right.content-after-open {
         width: 100% !important;
     }

     .prodcuts_listing_page {
         padding: 40px 0px 0px;
     }

     ul.vendor_onlycategries li {
         flex: 33.33% 1;
         max-width: 33.33%;
         margin-bottom: 15px;
     }

     .main_onlycategries {
         display: grid;
         grid-template-columns: 25% 25% 25% 25%;
     }

     .main_onlycategries_title {
         font-size: 20px;
     }

     button.notificationClass.dropdown-toggle.btn.btn-success {
         margin-left: 2px;
     }

     .header nav.nav, .login-fix-mobile {
         display: none;
     }

     .headerright .ressell-fix-mobile .sellstuffbtn {
         margin-right: 0px;

     }

     .headerright .ressell-fix-mobile .sellstuffbtn:hover {
         background: #ee07e5;
     }

     .headerright .ressell-fix-mobile {
         position: fixed;
         bottom: 10px;
         left: 50%;
         white-space: nowrap;
         transform: translateX(-50%);
         display: block;
         z-index: 99;
     }

     .header nav ul li:not(:last-child) {
         border-right: 0px;
     }

     .pxl-anchor-divider {
         display: block;
     }

     .header nav ul li.nav-item .btn {
         margin-left: 0px;
         margin-top: 20px;
         width: auto;
         display: inline-block;
     }

     .header nav ul li.nav-item .btn i {
         margin-left: 20px;
         line-height: 40px;
         text-align: center;
     }

     .header {
         padding: 10px 0px;
     }

     .Seller_edit_promain {
         padding: 30px 0px;
     }

     .selller_edit_sellpromain {
         margin-left: 0px;
     }
     .chatrt_msg_footer .nn_chatbtctn {
        overflow-x: auto;
     }
     .chatrt_msg_footer .nn_chatbtn {
        white-space: nowrap;
     }
     .backarrow_chat button,
     .backarrow_chat {
        padding-left: 0px;
     }
     .chat_flex_con_right .chatrt_msg_body{
        height: calc(100% - 223.01px);
     }
     .chatrt_msg_body .nn_leftpart .nortltrans {
        font-size: 16px;
     }
     .chatrt_msg_footer .nn_chatbtmain {
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
     }
 }

 @media (max-width: 767.98px) {
     .main_onlycategries {
         grid-template-columns: 33.33% 33.33% 33.33%;
     }

     .notificationbost_msg.scrollable {
         height: 300px;
         overflow: auto;
     }

     .btn-wrapper {
         flex-wrap: wrap;
     }

     .nn_prochatbtn1.butonChat {
         margin-right: 0px;
     }
     .prodcuts_Details_inner .proDescription, .details_oUter {
        padding: 15px;
     }
     .map_prodcts {
        padding: 0px 0px 50px;
    }
    .map_prodcts h2 {
        margin-top: 10px;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .pxl-header-menu-scroll  nav.pxl-header-nav{
        overflow-y: auto;
        height: calc(100vh - 180px);
    }
     .thumbSlider_box {
         background-color: rgb(242, 242, 242);
         padding: 15px;
         margin: 0px;
         border-radius: 10px;
         height: auto;
         object-fit: contain;
     }

     .delete_modal .modal-dialog.modal-dialog-centered {
         width: 100%;
         height: 100%;
         margin: 0px;
         border-radius: 0px;
         max-width: unset;
     }

     .delete_modal .modal-content {
         border-radius: 0px;
     }

     .Myplo_orderview .myplo_addressbtn1 {
         width: auto;
     }

     .prodcuts_Details_page .mainSwiper img {
         height: 300px;
     }
     .prodcuts_Details_page {
        padding: 30px 0px 40px;
     }
     .contact_page_myplo {
         padding: 40px 0px 20px;
     }
     section.prodcuts_Details_page  ol.breadcrumb  {
        row-gap: 10px;
     }
     .breadcrumbs_pro .breadcrumb-item {
        padding-left: 0px;
     }
     .breadcrumbs_pro .breadcrumb-item+.breadcrumb-item::before {
        padding: 0px;
     }
     .myplo_aboutus_page,
     .partysuplies-cateris {
         padding: 40px 0px;
     }

     .ReactModalPortal .slide-pane__close {
         margin-left: 5px;
     }

     .notification-inner.dropdown-menu {
         width: 340px;
     }

     ul.vendor_onlycategries li {
         flex: 50% 1;
         max-width: 50%;
     }

     .myplo-title-h2 h2 {
         font-size: 30px;
     }

     .myplo-title-h2 h2 .shape {
         width: 72px;
         height: 42px;
     }

     .fitler-cardsadd .form-control {
         font-weight: bold;
         display: block;
         margin-bottom: 20px;
     }

     .Filter_custom-modal .fitler-cardsadd {
         padding: 15px;

         box-shadow: rgba(0, 0, 0, 0.32) 0px 2px 9px 1px;
     }

     .hero-sec {
         height: auto;
         padding: 40px 5px 80px;
     }

     .select-form-hero {
         flex-wrap: wrap;
     }

     .fitler-cardsadd .form-control, .select-form-hero .form-control, .select-form-hero .search-Btn {
         width: 100%;
     }

     .mpplo_download_appcnt h3 {
         font-size: 40px;
         margin-bottom: 20px;
         line-height: normal;
     }

     .myplo_googlepay img {
         padding: 0px 5px;

     }

     .myplo_googlepay {
         margin: 0px 5px;
         width: 160px;
     }

     button.btn.location-button, .search-box {
         width: 100%;
     }

     .Popup-cartopen {
         padding: 15px;
     }

     .filepicker.dropzone.dz-clickable p {
         margin: 0px 0px;
     }

     .slide-pane_from_right .filepicker.dropzone.dz-clickable.dz-started .img_cvr.nn_stuffimg img, .dz-clickable.fx_img.nn_selling_img~.dz-clickable.fx_img.nn_selling_img .img_cvr.nn_stuffimg img, .dz-clickable.fx_img.nn_selling_img~.dz-clickable.fx_img.nn_selling_img .img_cvr.nn_stuffimg img {
         width: 72px;
         height: 72px;
     }

     .filepicker.dropzone.dz-clickable.dz-started:last-child::after {
         text-align: center;
         font-size: 30px;
         margin: 0px 10px 0px 0px;
         padding: 0px;
         width: 60px;
         height: 60px;
         display: block;
         line-height: 60px;
     }
 }

 @media (max-width: 575.98px) {
     .myplo-title-h2 {
         flex-wrap: wrap;
         margin-bottom: 20px;
     }

     .myplo-title-h2 h2 .shape {
         top: -3px;
     }

     .myplo-title-h2 h2 {
         margin-bottom: 18px;
         font-size: 25px;
     }

     .see_all-all {
         width: fit-content;
         margin-left: auto;
     }

     .Filter_custom-modal .fitler-cardsadd .main_onlycategries {
         grid-template-columns: 50% 50%;
     }

     .main_onlycategries {
         grid-template-columns: 50% 50%;
     }

     .Filter_custom-modal .fitler-cardsadd .main_onlycategries li {
         border: thin solid #eaecf0;
     }

     .Filter_custom-modal .fitler-cardsadd .main_onlycategries.main_onlycategries li:after {
         display: none;
     }

     .hero-sec h1 {
         font-size: 40px;
     }

     .myplo_btn_wrapper .cmn-btn2 {
         width: 100%;
     }

     .myplo_addressbtn1 {
         padding: 8px 18px;
         width: 100%;
     }

     .cmn-btn2 {
         padding: 13px 20px;
     }
 }

 @media (max-width: 475.98px) {
     .main_onlycategries {
         grid-template-columns: 100%;
     }

     .main_onlycategries li:after {
         display: none;
     }

     .Filter_custom-modal .fitler-cardsadd .main_onlycategries {
         grid-template-columns: 50% 50%;
     }

     .hero-sec h1 {
         font-size: 30px;
     }

 }