@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
    --heading-font: "Ubuntu", sans-serif;
    --theme-color: #e71de1;
    --heading-color: #333;
    --theme-color-hvr: #e71de1bf;
    --subtheme-color: #ffffff;
    --subtheme-color-hvr: #ffffff;
    --e-global-color-primary: #e71de1;
    --e-global-color-secondary: #000000;
    --e-global-color-text: #666666;
    --e-global-color-accent: #61CE70;
    --e-global-color-32b3a35: #FFFFFF;
    --btn-color: #00EE00;
    --primary-color: #00EE00;
    --secondary-color: #000;
    --theme-color-rgp: 254 58 86;
    --theme-fontfamily: "Hanken Grotesk", serif;
}

html {
    padding: 0px;
    margin: 0px;
}

ul {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

body {
    margin: 0;
    font-family: var(--theme-fontfamily);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 992px) {
    .ReactModalPortal .slide-pane__overlay {
        z-index: 99;
    }

    .ReactModalPortal .slide-pane__overlay.overlay-after-open {
        background-color: rgba(0, 0, 0, 0.5) !important;
        z-index: 100;
    }

    .ReactModalPortal .slide-pane__overlay.overlay-after-open .slide-pane_from_right.content-after-open {
        max-width: 500px;
        margin: 0px auto;
        bottom: auto !important;
        border: none !important;
    }
}

.ReactModalPortal .slide-pane__overlay.overlay-after-open {
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 100;
}

.ReactModalPortal .slide-pane__overlay.overlay-after-open .slide-pane_from_right.content-after-open {
    position: fixed;
    right: 0px;
    max-width: 500px;
    top: 0px !important;
}

/* Sell Your stuff leftover */
.ReactModalPortal .slide-pane__header {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border: none !important;
    background: none !important;
}

.slide-pane__close svg {
    padding: 0px;
    fill: rgb(189 189 189);
    width: 24px !important;
}

.slide-pane__title-wrapper {
    margin-left: 10px !important;
}

.slide-pane .slide-pane__title {
    font-size: 24px !important;
    font-weight: bold !important;
}

.slide-pane__subtitle {
    font-size: 12px;
    margin-top: 2px;
}

.slide-pane__content {
    padding: 0px !important;
}

.ReactModalPortal .slide-pane__close {
    margin-left: -11px;
    box-shadow: rgba(246, 246, 246, 0.5) 0px 0px 1px 0px, rgba(0, 10, 18, 0.12) 0px 2px 4px 0px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    width: 40px;
    height: 40px;
    opacity: 1;
    padding: 7px 0px;
    text-align: center;
    margin-top: -4px;
}

.slide-pane__header>.slide-pane__close {
    background: none;
    box-shadow: none;
}

.footer-filterbtn {
    margin-top: 25px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 3px 0px;
    position: sticky;
    bottom: 0px;
    width: auto;
    background-color: rgb(255, 255, 255);
    z-index: 999;
    margin-left: -25px;
    margin-right: -25px;
    padding: 14px 14px 50px;
}

.filter_btn-modal {
    cursor: pointer;
    display: inline-block;
    text-overflow: ellipsis;
    border: 1px solid var(--theme-color);
    font-family: var(--theme-fontfamily);
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    text-decoration: none;
    overflow: hidden;
    margin: 0px;
    background: var(--theme-color);
    transition: background-color 0.25s;
    padding: 0px 1.4rem;
    border-radius: 50px;
}

.slide-pane__content select.form-control {
    box-shadow: rgba(0, 0, 0, 0.32) 0px 2px 6px 0px;
    margin-top: 20px;

}

.Popup-cartopen {
    padding: 25px;
}

.slide-pane__content label {
    line-height: 1.42857;
    margin-bottom: 11px;
    font-size: 16px;
    font-weight: 600;
    display: block;
    color: #000;
}


.photosuplod-sell .card:hover {
    border: 2px solid rgb(189, 189, 189);
}

.photosuplod-sell .card .card-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex: 1 1 0%;
    min-height: 300px;
}

.photosuplod-sell .card {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 5px 0px;
    border: 2px solid rgb(245, 245, 245);
    border-radius: 12px;
    margin-top: 0px;
    padding: 5px 0px;
}

.dz-clickable.fx_img.nn_selling_img button svg {
    width: 15px;
    height: 15px;
    color: rgb(255, 255, 255);
    fill: rgb(255, 255, 255);
}

.dz-clickable.fx_img.nn_selling_img button {
    position: absolute;
    right: 4px;
    bottom: 4px;
    background-color: rgb(255 0 0);
    border: none;
    color: rgb(255, 255, 255);
    border-radius: 24px;
    padding: 0px 5px 2px;
    margin: 0px 2px 2px 0px;
}

.filepicker.dropzone.dz-clickable p {
    color: rgb(117, 117, 117);
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    margin: 0px 70px;
}

.filepicker.dropzone.dz-clickable h1 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}

.filepicker.dropzone.dz-clickable.dz-started,
.filepicker.dropzone.dz-clickable {
    text-align: center;
    width: 100%;
}

.dz-clickable.fx_img.nn_selling_img {
    margin-bottom: 15px;
}

.filepicker.dropzone.dz-clickable.dz-started .img_cvr.nn_stuffimg {
    position: relative;
}

.dz-clickable.fx_img.nn_selling_img~.dz-clickable.fx_img.nn_selling_img {
    margin: 0px 10px 10px 0px;
    width: auto;
    display: inline-block;
}

.dz-clickable.fx_img.nn_selling_img~.dz-clickable.fx_img.nn_selling_img .img_cvr.nn_stuffimg img {
    width: 88px;
    height: 88px;
}

.filepicker.dropzone.dz-clickable.dz-started .img_cvr.nn_stuffimg img {
    width: 100%;
    object-fit: contain;
    height: 375px;
    background-color: rgb(242, 242, 242);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 5px 0px;
    border-radius: 8px;
}

.slide-pane_from_right .dz-clickable.fx_img.nn_selling_img {
    margin: 0px 10px 10px 0px!important;
    width: auto;
    display: inline-block;
}

.slide-pane_from_right .filepicker.dropzone.dz-clickable.dz-started .img_cvr.nn_stuffimg img {
    width: 88px;
    height: 88px;
}

.slide-pane_from_right .filepicker.dropzone.dz-clickable.dz-started, .filepicker.dropzone.dz-clickable {
    text-align: left;
}

.filepicker.dropzone.dz-clickable.dz-started .img_cvr.nn_stuffimg img:not(:first-child) {
    width: 200px;
    /* Applies to all images except the first one */
}

.slide-pane_from_right  .photosuplod-sell .card .card-body {
    box-sizing: border-box;
    display: block;
    min-height: 210px;
    padding-bottom: 5px;
}
.slide-pane_from_right .dropZoneDefault {
    text-align: center;
}
.filepicker.dropzone.dz-clickable.dz-started:last-child::after {
    content: "+";
    border-radius: 8px;
    text-align: center;
    line-height: 90px;
    font-size: 40px;
    margin: 0px 15px 0px 5px;
    color: var(--theme-color);
    padding: 20px 35px;
    vertical-align: middle;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 1px 1px;
}

.dz-clickable.fx_img.nn_selling_img~.dz-clickable.fx_img.nn_selling_img .img_cvr.nn_stuffimg img {
    width: 88px;
    height: 88px;
}

.filepicker.dropzone.dz-clickable.dz-started .dz-default.dz-message {
    display: none;
}

.car-mpas-add iframe {
    border-radius: 6px;
}

.car-mpas-add input {
    box-sizing: border-box;
    border: 1px solid transparent;
    margin: 25px 25px 0px;
    width: 88%;
    height: 32px;
    padding: 0px 12px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
    font-size: 14px;
    outline: none;
    position: absolute;
    left: 0px;
    top: 0px;

}

.car-mpas-add {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    font-size: .875rem;
    margin-top: 30px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 5px 0px;
    margin-bottom: 30px;
    border-radius: 6px;
    flex-direction: column;
    padding: 0.9375rem 20px;
}

p.mpplo_des-number {
    margin: 25px 0px 0px;
}

.border-form-control.form-control:focus {
    border-bottom: 2px solid #e71de1;
}

.border-form-control.form-control {
    border: 0px;
    border-bottom: 1px solid #D2D2D2;
    padding: 0px;
    border-radius: 0px;
}

/* filter header modal header */

.Filter_custom-modal .fitler-cardsadd .main_onlycategries a img {
    height: 50px;
    width: 50px;
    border-radius: 50px;object-fit: cover;
}

.Filter_custom-modal .fitler-cardsadd {
    padding: 20px 45px 20px 45px;

    box-shadow: rgba(0, 0, 0, 0.32) 0px 2px 9px 1px;
}

.Filter_custom-modal .modal-header {
    border-bottom: 1px solid rgb(238, 238, 238);
    padding: 8px;
}

.Filter_custom-modal .modal-header button {
    display: inline-block;
    padding-left: calc(6px);
    padding-right: calc(6px);
    align-self: auto;
    flex: 0 0 auto;
    vertical-align: bottom;
    padding-top: 0px;
    padding-bottom: 0px;
}

.Filter_custom-modal .modal-header i {
    font-size: 22px;
    width: 40px;
    height: 40px;
    color: rgb(189, 189, 189);
    background: transparent;
    border: none;
    line-height: 40px;
}

.Filter_custom-modal .modal-header {
    display: block;
}

.Filter_custom-modal .modal-header .modal-title {
    font-size: 18px;
    color: rgb(44, 44, 44);
    display: inline-block;
    padding-right: calc(6px);
    align-self: auto;
    flex: 1 1 0%;
    margin-bottom: .5rem;
    font-weight: 500;
}

.Filter_custom-modal .myplo-title-h2 h2 .shape {
    z-index: 1;
}

/* filter header modal header end */
/* header */
.resfilte-filter-dektop button {
    background: transparent;
    border: none;
}

.resfilte-filter-dektop button svg {
    fill: rgb(255, 255, 255);
}

.resfilte-filter-dektop {
    margin-top: -5px;
}

.header nav.nav {
    display: block;
}


.header nav ul>li>span:hover,
.header nav ul>li>a:hover {
    color: #f902e3;
}

.header nav ul {
    list-style: none;
    padding-left: 0px;
}

.inner-menu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.header nav ul li.nav-item .btn::before {
    display: none;
}

.header nav ul li.nav-item .btn {
    line-height: normal;
    padding: 5px 6px 5px 15px;
    color: #fff;
    margin-left: 40px;
}

.header nav ul li.nav-item .btn i {
    margin-left: 20px;
}

ul.nav-list {
    display: flex;
    align-items: center;
}


.header {
    background-image: linear-gradient(90deg, #0f6aff, #f902e3) !important;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 10px 0px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    position: fixed;
    top: 0px;
    transition: 0.5s;
    z-index: 99;
}

.header.pxl-header-fixed {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    transition: .6s;
}

.header nav ul li span,
.header nav ul li a {
    display: inline-block;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #fff;
    padding: 10px 16px;
}

.header nav ul li span, .header nav ul li a:focus {

    color: white;

}


.header .logo img {
    width: auto;
    height: 77.51px;
}

#pxl-header-elementor .pxl-header-elementor-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    top: 0;
    transform: translateY(-120%);
    transition: .6s cubic-bezier(.24, .74, .58, 1);
    visibility: hidden;
}

/* OffCanvas.css */

.pxl-header-menu {
    top: 0px;
    width: 300px;
    right: 0px;
    height: 100%;
    opacity: 1;
    z-index: 6;
    position: fixed;
    box-shadow: rgba(246, 246, 246, 0.5) 0px 0px 1px 0px, rgba(0, 10, 18, 0.2) 0px 3px 8px 0px;
    transition: right 250ms ease-in-out 0s;
    pointer-events: all;
    overflow-y: scroll;
    display: none;
    background-color: white;
    z-index: 9999;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    border: 2px solid rgb(222, 226, 230);
}

::-webkit-scrollbar {
    appearance: none;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgb(222, 226, 230);
}

.pxl-header-menu.show {
    display: block;
}

/* 
.pxl-header-menu-scroll {
    padding: 20px;
} */
.login-sidebar-header img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    -o-object-fit: cover;
    object-fit: cover;
}

.login-sidebar-header {
    background-image: linear-gradient(90deg, #0f6aff, #f902e3) !important;
    text-align: center;
    width: 100%;
    color: rgb(255, 255, 255);
    height: 180px;
    padding: 24px 16px;
    box-sizing: border-box;
    background-color: var(--theme-color);
}

.login-sidebar-header h2 {
    cursor: pointer;
    font-size: 20px;
    margin-top: 10px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
}

.login-sidebar-header p {
    cursor: pointer;
    font-size: 14px;
    margin-top: 2px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    color: var(--subtheme-color);
    margin-bottom: 0px;
}

.pxl-header-menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.pxl-nav-mobile-button {
    cursor: pointer;
}

.pxl-icon-line {
    display: block;
    height: 3px;
    width: 25px;
    background-color: #000;
    border-radius: 100px;
}

.pxl-menu-close {
    cursor: pointer;
    color: #fff;
    font-size: 24px;
}

.pxl-logo-mobile.pxl-hide-xl img {
    width: 140px;
    margin-bottom: 30px;
}

.pxl-anchor-divider .pxl-icon-line.pxl-icon-line1 {
    top: 0;
    width: 22px;
    right: 0;
    left: auto;
}

.pxl-anchor-divider .pxl-icon-line {
    height: 3px;
    position: absolute;
    right: 0;
}

.pxl-anchor-divider .pxl-icon-line.pxl-icon-line2 {
    top: 10px;
    width: 30px;
    right: 0;
    left: auto;
}

.pxl-anchor-divider .pxl-icon-line.pxl-icon-line3 {
    left: auto;
    width: 20px;
    right: 0;
    bottom: 3px;
}

.pxl-anchor-divider {
    overflow: hidden;
    width: 36px;
    height: 26px;
    cursor: pointer;
    position: relative;
}

.pxl-close:before {
    -webkit-transform: rotate(45deg);
    -khtml-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.pxl-close:before,
.pxl-close:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    transform-origin: 50% 50%;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -khtml-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -moz-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -ms-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    background-color: #1b1b1b;
}

.pxl-close:before {
    -webkit-transform: rotate(45deg);
    -khtml-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.pxl-close:after {
    -webkit-transform: rotate(-45deg);
    -khtml-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.pxl-close {
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background: rgba(255, 255, 255, .7);
    border: 9px solid transparent;
    color: #777;
    width: 36px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

nav.pxl-header-nav ul {
    list-style: none;
    padding: 0px;
    margin-top: 20px;
}

nav.pxl-header-nav ul li a i {
    background: -webkit-linear-gradient(top, rgb(15, 106, 255), rgb(249, 2, 227)) text;
    display: block;
    text-align: left;
    width: 30px;

    -webkit-text-fill-color: transparent;
}

nav.pxl-header-nav ul li span.menu-subicon {
    font-size: 22px;
    display: inline-block;
    text-align: center;
    padding: 0px;
}

nav.pxl-header-nav ul li a:hover {
    background-color: rgba(34, 98, 253, 0.14);
}

nav.pxl-header-nav ul li a:hover {
    color: inherit;
}

nav.pxl-header-nav ul li a {
    font-size: 14px;
    padding: 10px 10px 10px 20px;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: flex;
}

.notification-inner.dropdown-menu {
    width: 360px;
    border: 0;
    padding: 0;
    top: 10px !important;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 .5rem 1rem rgb(0 0 0 / 15%);
}

.msg-header {
    padding: .8rem 1rem;
    border-bottom: 1px solid #ededed;
    background-clip: initial;
    background: #fff;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 10px;
    margin-bottom: 0;
    border-top-right-radius: 10px;
}

.msg-header .msg-header-title {
    font-size: 16px;
    color: #1c1b1b;
    margin-bottom: 0;
    font-weight: 700;
}

.msg-header .msg-header-clear {
    font-size: 14px;
    color: #585858;
    margin-bottom: 0;
    background: transparent;
    border: 0px;
}

.notification-inner.dropdown-menu .msg-name {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 700;
}

.msg-info {
    font-size: 14px;
    margin-bottom: 0;
}

.dropdown-item {
    word-break: unset;
    padding: 10px 20px;
    border-bottom: 1px solid #ededed;
    white-space: break-spaces;
}

.headerright {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.headerright .sellstuffbtn i {
    font-size: 22px;
}

.headerright .sellstuffbtn {
    transition: 0.4s;
    border: 1px solid rgb(249, 2, 227);
    color: rgb(15, 106, 255);
    background: white;
    margin-right: 12px;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
}

button.notificationClass.dropdown-toggle.btn.btn-success span.count-dwons {
    color: #fff;
    background-color: #3f51b5;
    top: -11px;
    right: -11px;
    width: 22px;
    height: 22px;
    display: flex;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
    font-size: 0.75rem;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    align-content: center;
    border-radius: 50%;
    flex-direction: row;
    justify-content: center;
}

button.notificationClass.dropdown-toggle.btn.btn-success i {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 20px;
}

button.notificationClass.dropdown-toggle.btn.btn-success {
    background: transparent;
    border-color: transparent;
    padding: 0;
    margin-right: 10px;
    position: relative;
}

.notificationClass.dropdown-toggle::after {
    display: none;
}

.headerright button.menubtn svg {
    width: 25px;
    height: 35px;
    color: #fff;
    fill: currentColor;
}

.headerright button.menubtn {
    padding: 10px;
    background: transparent;
    border: 0px;
    color: #fff;
}

.headerright .sellstuffbtn:hover {
    color: rgb(255, 255, 255);
    background: transparent;
    border-color: rgb(255, 255, 255);
    box-shadow: none;
}

/* footer start css */
.logofooters img {
    width: 140px;
    margin-bottom: 20px;
}

.copyright-myplo {
    margin-top: 60px;
    padding: 15px 0px;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.copyright-myplo p {
    color: hsla(0, 0%, 100%, .7);
    font-weight: 400;
    font-size: 16px;
    line-height: 2;
    margin: 0;
    opacity: 0.9;
    text-align: center;
}

footer.footer {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: auto;
    width: 100%;
    padding-top: 100px;
}

.quicklink {
    display: flex;
    align-items: center;
    grid-column-gap: 12px;
    column-gap: 12px;
    grid-row-gap: 12px;
    row-gap: 12px;
    flex-wrap: wrap;
}

.buttonfoot {
    background: transparent;
    color: #fff;
    padding: 6px;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid #fff;
    border-radius: 8px;
    width: 120px;
    text-align: center;
    justify-content: center;
    grid-column-gap: 6px;
    column-gap: 6px;
    display: flex;
    align-items: center;
}

.social-media-links li:first-child {
    padding-left: 0px;
}

.social-media-links li {
    list-style: none;
    padding: 0px 15px;
    margin: 0px;
    display: inline-block;
}

.social-media-links li a {
    color: var(--subtheme-color);
    font-size: 20px;
}

.social-media-links i {
    transition: .4s;
    display: inline-block;
    font-size: 14px;
}

.social-media-links i:hover {
    transform: translateY(-5px);
    color: rgb(15 106 255)
}

.buttonfoot:hover {
    background: #7c63f1;
}

.listing-contact-info {
    margin: 0;
    padding: 0;
    list-style: none;

}

.listing-contact-info li {
    margin-bottom: 10px;
}

.logofooters .listing-contact-info li {
    margin-bottom: 28px;
}

.listing-contact-info li a:hover {
    color: #0f6aff;
}

.listing-contact-info:hover i {
    background: #0f6aff;
    color: #fff;
}

.listing-contact-info a {
    color: hsla(0, 0%, 100%, .7);
    font-size: 16px;
    align-items: center;
    display: flex;
    transition: .3s;
    font-weight: 500;
}

.listing-contact-info a i {
    color: #fff;
    background: hsla(0, 0%, 100%, .3);
    transition: .4s;
    display: inline-block;
    text-align: center;
    margin-right: 7px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    font-size: 20px;
}

.column-cateries .listing-contact-info {
    columns: 2;
}

.footerTittle {
    color: #fff;
    font-size: 20px;
    margin-bottom: 34px;
    font-weight: 500;
}

.input-filled .form-control:focus {
    box-shadow: none;
    background: transparent;
    color: hsl(0deg 0% 100% / 70%)
}

.input-filled input.form-control::placeholder {
    color: hsl(0deg 0% 100% / 70%)
}

.input-filled input.form-control {
    background: transparent;
    border: 0px;
    color: hsl(0deg 0% 100% / 70%);
    border-radius: 0px;
    padding: 0px;
}

.input-filled {
    border-radius: 8px;
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 15px;
    border: 1px solid hsla(0, 0%, 100%, .2);
    background: 0 0;
}

.input-filled button {
    height: 42px;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #fff;
    color: #000;
    font-weight: 600;
    line-height: 1;
    border: 0;
    display: flex;
    align-items: center;
}

.footer-widgets p {
    color: hsla(0, 0%, 100%, .7);
    margin-bottom: 28px;
}

.mpplo_download_appcnt {
    padding: 45px 0px;
    text-align: center;
}

.mpplo_download_appcnt h3 {
    font-size: 55px;
    font-weight: 600;
    color: #333;
    margin-bottom: 25px;
    line-height: 1.6;
}

.mpplo_download_appcnt span {
    color: #f205e4;
}

.mpplo_download_appcnt .free-app {
    color: #1f62fd;
}

.myplo_googlepay {
    width: 200px;
    margin: 0px 15px;
    height: auto;
}

.myplo_googlepay img {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.myplo_footerapps {
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;

}

.margindiv-header {
    padding-top: 93px;
}

/* hero section  */
.select-form-hero {
    background: #fff;
    padding: 20px 20px 0;
    border-radius: 8px;
    display: flex;
    gap: 10px;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.slide-pane__content select.form-control,
.fitler-cardsadd .form-control,
.select-form-hero .form-control,
.Add_addres_modal .form-control {
    border: 1px solid #ddd;
    width: 100%;
    height: 50px;
    background: #fff;
    padding: 11px 19px;
    color: #667085;
    line-height: 25px;
    border-radius: 8px;
    margin-bottom: 0;
    font-size: 16px;
    box-sizing: border-box;
}


.select-form-hero .search-Btn {
    color: #fff;
    font-family: var(--heading-font);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(90deg, #0f6aff, #f902e3);
    line-height: 1px;
    text-align: center;
    display: block;
    padding: 15px;
    border-radius: 8px;
    text-transform: capitalize;
    border: 0;
}

.slide-pane__content select.form-control,
.contact-outer_myplo select.form-control,
.fitler-cardsadd select.form-control,
.select-form-hero select.form-control {
    background: url("data:image/svg+xml,%3Csvg focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(0,0,0,.54)' d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'%3E%3C/path%3E%3C/svg%3E") no-repeat 98% 46% !important;
    background-size: 35px !important;
}

.hero-sec {
    height: 616px;
    background-position: 50%;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-sec h1 {
    font-size: 55px;
    color: #000;
    font-weight: 700;
    margin-bottom: 29px;
    line-height: 1.3;
    text-align: center;
}

.myplo-title-h2 p {
    color: #212529;
    font-weight: 300;
}

.myplo-title-h2 {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.myplo-title-h2 h2 .shape {
    position: absolute;
    width: 72px;
    height: 50px;
    background-image: linear-gradient(90deg, rgba(15, 106, 255, .6705882352941176), rgba(249, 2, 227, .4392156862745098));
    z-index: -1;
    left: 0;
    top: 0;
    margin: 0;
    line-height: 1;
}

.myplo-title-h2 h2>span {
    color: #0f6aff;
    font-size: 82px;
}

.myplo-title-h2 h2 {
    color: var(--heading-color);
    font-family: var(--heading-font);
    font-size: 36px;
    display: block;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 0px;
    padding-left: 12px;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.New_listed-pro {
    padding: 40px 0px;
}

.product-boxes {
    position: relative;
}

.nn_homproductctn {
    border-radius: 8px 8px 0px 0px;
}

.freeproducts {
    background: var(--theme-color);
    color: #635c5c;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px 20px 20px 0px;
    z-index: 1;
}

.product-boxes img {
    height: 230px;
    width: 100%;
    object-fit: cover;
}

.product-boxes_inner h2 a {
    color: var(--heading-color);

}

.product-boxes_inner h2 {
    margin-bottom: 2px;
    line-height: 1.5;
    color: var(--heading-color);
    font-weight: 500;
    font-size: 22px;
    display: block;
    text-decoration: none;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.product-boxes_inner p {
    color: rgb(117, 117, 117);
    margin-bottom: 10px;
    overflow: hidden;
    font-size: 16px;
    max-width: 100%;
    line-height: 1.2em;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.height_normal_pro .product-boxes_inner {
    min-height: unset;
}
.product-boxes_inner {
    margin: -30px auto 0px;
    min-height: 200px;
    background: #fff;
    padding: 12px 20px 15px;
    left: 0;
    right: 0;
    border-radius: 20px;
    box-shadow: 0 3px 12px rgb(45 23 191 / 9%);
    position: relative;
    z-index: 0;
}

.product-boxes_inner .price-products {
    margin-bottom: 10px;
    font-family: var(--heading-font);
    color: var(--heading-color);
    font-weight: 700;
    font-size: 20px;
    display: block;
}

.product-boxes:hover .product-boxes_inner h2 a {
    color: var(--e-global-color-primary);
}

.nn_homproductctn {
    overflow: hidden;
    display: block;
}

.product-boxes img {
    transition: 0.3s;
}

.product-boxes:hover img {
    transform: scale(1.2);
}

/* category add css */

.main_onlycategries a img {
    height: 130px;
    width: 130px;
    display: inline-block;
    position: relative;
    object-fit: cover;
}

.main_onlycategries_img {
    text-align: center;
    margin-bottom: 25px
}

.main_onlycategries li {
    padding-bottom: 40px;
    height: 100%;
    padding-top: 40px;
    border-bottom: 1px solid #eaecf0;
    margin-bottom: 0;
    list-style: none;
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
}

.main_onlycategries li:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    background-color: #eaecf0;
    height: 100%;
    transform: translateY(-50%);
}

.main_onlycategries {
    white-space: nowrap;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    scroll-behavior: smooth;
    overflow-x: auto;
    position: relative;
    scrollbar-width: none;
    display: grid !important;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

.main_onlycategries_title {
    color: #333;
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 6px;
    font-weight: 500;
    display: block;
    text-align: center;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap */
    white-space: break-spaces;
    word-break: break-word;
}

.vendor_onlycategries_img {
    margin-bottom: 25px;
    text-align: center;
}

ul.vendor_onlycategries {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
    text-align: center;
}

ul.vendor_onlycategries li {
    padding-left: 15px;
    padding-right: 15px;
    flex: 20% 1;
    max-width: 20%;
}

.vendor_onlycategries_img img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border-radius: 100px;
}

.vendor_onlycategries_title {
    color: rgb(51, 51, 51);
    font-size: 20px;
    line-height: 1.4;
    font-weight: 500;
    display: block;
    text-align: center;
    word-break: break-word;
    white-space: break-spaces;
    margin-top: 20px;
}

.see_all-all {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #f404e4;
}

.inner-catgigstitle i {
    font-size: 30px;
    margin-right: 5px;
    color: rgb(85, 96, 128);
}

.inner-catgigstitle h4 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 19px;
    color: #333;
}

.inner-catgigstitle h6 {
    font-family: "Inter", sans-serif;

    font-style: normal;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #333;
}

.inner-catgigstitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}

.product-boxes_inner i.active {
    color: rgb(233, 147, 24)
}

.product-boxes_inner .fa-location-dot {
    color: rgb(117, 117, 117);
    font-size: 13px;
    margin-right: 2px;
}

.product-boxes_inner i {
    color: rgb(164, 164, 164)
}

ul.main_onlycategries li:hover h2 {
    color: #0f6aff !important;
}

/* myplo_btn_wrapper */
.myplo_btn_wrapper {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
}

.cmn-btn2:hover,
.cmn-btn2:focus {
    color: #FFFFFF;
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
    background-color: #999999;
}

.cmn-btn2 {
    font-family: var(--heading-font);
    transition: .4s;
    border: 1px solid transparent;
    background-image: linear-gradient(90deg, #0f6aff, #f902e3);
    color: #fff;
    padding: 13px 44px;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    display: inline-block;
    text-transform: capitalize;
    border-radius: 8px;
    text-decoration: none;
}

/* scrolltop */
.anchor-fixed {
    position: fixed;
    bottom: 65px;
    right: 15px;
    width: 45px;
    height: 45px;
    z-index: 9;
    cursor: pointer;
    background: var(--theme-color);
    box-shadow: rgba(255, 255, 255, 0.22) 0px 1px 3px 2px;
    border: 1px solid var(--subtheme-color);
    border-radius: 50px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

/* about us page css */
.myplo_aboutus_page p {
    font-size: 20px;
    font-weight: 400;
    color: #667085;
    line-height: 25px;
}

.myplo_aboutus_page {
    padding: 50px 0px;
}

.partysuplies-cateris {
    padding: 60px 0px 120px;
}

.myplo_aboutus_page img {
    width: 100%;
}

.myplo_aboutus_page .priourpg span,
.myplo_aboutus_page .priourpg p,
.myplo_aboutus_page .priourpg p span {
    color: #667085;
    font-size: 16px;
    font-weight: 400;
}


/* contact us page css */
.contact_page_myplo img {
    width: 100%;
}

.contact_page_myplo {
    padding: 80px 0px;
}

.contact-outer_myplo label {
    margin-bottom: 11px;
    font-size: 16px;
    font-weight: 600;
    display: block;
    color: #000;
}

.contact-outer_myplo .checkWrap2 {
    font-size: 14px;
    color: #757575;
    font-family: var(--heading-font);
    display: block;
    position: relative;
    margin-bottom: 25px;
    cursor: pointer;
    margin-top: 15px;
    line-height: 25px;
}

.loginModalMyplo_Right input.form-control {
    height: 40px !important;
}

.loginModalMyplo_Right input.form-control,
.contact-outer_myplo input.form-control,
.contact-outer_myplo select.form-control {
    border: 1px solid #ddd;
    width: 100%;
    height: 50px;
    background: #fff;
    padding: 11px 19px;
    color: #667085;
    line-height: 25px;
    border-radius: 8px;
    margin-bottom: 0;
    font-size: 16px;
    box-sizing: border-box;
}

.contact-outer_myplo textarea.form-control {
    height: 120px;
    border: 1px solid #ddd;
    width: 100%;
    background: #fff;
    padding: 11px 19px;
    color: #667085;
    line-height: 25px;
    border-radius: 8px;
    margin-bottom: 0;
    font-size: 16px;
    box-sizing: border-box;
}

.contact-outer_myplo .invalid-feedback {
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 8px;
    min-height: 1em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1em;
}

.contact-outer_myplo {

    background: rgb(255, 255, 255);
    box-shadow: rgba(26, 40, 68, 0.06) 0px 1px 80px 12px;
    padding: 20px;
    border-radius: 8px;
}

/* Product listing page css */
.myplonn_chatBtn {
    background-image: linear-gradient(to right, rgb(15, 106, 255), rgb(249, 2, 227));
    border: none;
    color: var(--subtheme-color);
    padding: 7px;
    line-height: 1;
    border-radius: 50px;
}

.prodcuts_listing_page {
    padding: 50px 0px;
}

.myplo_notFound {
    padding: 70px 0px;
    text-align: center;
}

.prodcuts_listing_flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.prodcuts_listing_colleft {
    width: 320px;
    position: sticky;
    top: 100px;
    z-index: 9;
    left: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(238, 238, 238);
    overflow-x: hidden;
    background: rgb(255, 255, 255);
    box-shadow: rgba(26, 40, 68, 0.06) 0px 1px 80px 12px;
    border-radius: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 30px;
}

.prodcuts_listing_colleft .filertcler .cleBtmn {
    padding: 0;
    font-weight: 700;
    color: #f603e3;
    border: none;
    background: rgb(255, 255, 255);
}

.prodcuts_listing_colleft .filertcler p {
    font-size: 22px;
    color: #000;
    margin: 0;
    font-weight: 700;
}

.prodcuts_listing_colleft .filertcler {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.prodcuts_listing_colright {
    width: calc(100% - 320px);
    padding-bottom: 110px;
}

.prodcuts_listing_colright {
    padding-left: 15px;

}

.prodcuts_listing_colleft .inpydeanrr {
    margin: 0px auto;
    padding: 20px 25px;
    width: 100%;
}

.inpydeanrr span.input-range__label.input-range__label--min,
.inpydeanrr span.input-range__label.input-range__label--max {
    display: none;
}

.inpydeanrr .input-range__slider {
    background: var(--theme-color);
    border: 1px solid var(--theme-color);
}

.inpydeanrr .input-range__track--active {
    background-color: var(--theme-color);
}

.inpydeanrr .input-range__label-container {
    color: var(--theme-color);
}

.inpydeanrr .input-range__label {
    font-family: inherit !important;
    font-weight: bold;
}

.prodcuts_listing_colleft label {
    display: block;
    font-size: 16px;
    text-transform: capitalize;
    color: rgb(0, 0, 0);
    font-weight: 600;
    padding-bottom: 5px;
}

.prodcuts_listing_colleft .nn_fliterctn {
    padding: 10px 15px;
    position: relative;
}

.labelName-filter .MuiFormControlLabel-label {
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.46429em;
}

.labelName-filter {
    display: block;
    width: 100%;
    margin-bottom: 5px;
}

.labelName-filter .MuiCheckbox-colorPrimary svg path {

    color: rgb(0 0 0 / 54%);
    fill: rgb(0 0 0 / 54%);
}

.labelName-filter .Mui-checked svg path {
    color: var(--theme-color);
    fill: var(--theme-color);
}

.Filter_custom-modal .prodcuts_listing_colleft {
    padding: 0px;
    border: 0px;
    box-shadow: none;
}

.chat-bu-header {
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.seaiconnss {
    background-image: linear-gradient(90deg, #0f6aff, #f902e3) !important;
    position: absolute;
    height: 100%;
    border-radius: 8px !important;
    width: 50px;
    display: flex;
    right: 0px;
    top: 0px;
    align-items: center;
    justify-content: center;
}

.searcbaricon input {
    overflow: hidden;
    padding-right: 60px;
    border: 1px solid #ddd;
    width: 100%;
    height: 50px;
    background: #fff;
    padding: 11px 19px;
    color: #667085;
    line-height: 25px;
    border-radius: 8px;
    margin-bottom: 0;
    font-size: 16px;
    box-sizing: border-box;
}

.searcbaricon {
    position: relative;
}

.seaiconnss svg {
    fill: #fff;
    width: 24px;
    height: 24px;
}

.myplochatimg .favbtn i.active {
    color: var(--theme-color) !important;
}

.myplochatimg .favbtn {
    font-size: 24px;
    background: #fff;
    border: 0px;
}

.myplochatimg {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}

/* login modal  */
.loginModalMyplo_outter {
    max-width: 640px;

}

.loginModalMyplo_outter .modal-content {
    border: 0px solid #ccc;
    background: #fff;
    border-radius: 4px;
    outline: none;
    overflow: hidden;
}

.loginModalMyplo {
    display: flex;
    position: relative;
}

.loginModalMyplo .closicon-login i {
    color: #fff;

    font-size: 20px;
}

.loginModalMyplo .closicon-login {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 41px;
    height: 41px;
    min-width: 41px;
    padding-left: 12px;
    padding-right: 12px;
}

.loginModalMyplo_Right {
    width: 55%;
    padding-bottom: 0px;
    height: calc(100vh - 3.50rem);
    padding: 15px 19px;
    overflow: auto;
    display: block !important;

}

.loginModalMyplo_left {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    position: relative;
    background-image: linear-gradient(to right, rgb(15, 106, 255), rgb(249, 2, 227));
    background-repeat: no-repeat;
    background-color: grey;
    background-size: cover;
    width: 45%;
}

.loginModalMyplo_inner h2 {
    font-weight: 800;
    font-size: 26px;
    margin-bottom: 32px;
    line-height: 35px;
}

.loginModalMyplo_inner {
    padding: 10px 30px;
    color: rgb(255, 255, 255);
}

.loginModalMyplo_inner li {
    font-size: 18px;
    display: flex;
    list-style: none;
    margin-top: 8px;
    margin-bottom: 8px;
}

.loginModalMyplo_inner li svg {
    box-sizing: border-box;
    width: 30px;
    display: block;
    margin-right: calc(12px);
    align-self: auto;
    flex: 0 0 auto;
    color: rgb(255, 255, 255);
    fill: rgb(255, 255, 255);
}

.firstep_login img {
    width: 160px;
    margin-bottom: 25px;
}

.loginModalMyplo_Right h3 {
    position: relative;
    width: 80%;
    margin: 0px auto 25px;
}

.loginModalMyplo_Right h3:before {
    position: absolute;
    content: "";
    width: 100%;
    left: 0px;
    height: 1px;
    background: rgb(227, 227, 227);
    top: 60%;
}

.loginModalMyplo_Right h3 span {
    padding: 0px 6px;
    background: rgb(255, 255, 255);
    font-weight: normal;
    color: rgb(153, 153, 153);
    font-size: 14px;
    line-height: 14px;
    position: relative;
}

.firstep_login {
    text-align: center;
}

p.footer-trms a {
    color: rgb(117, 117, 117) !important;
}

.footer-trms,
.firstep_login h2 {
    font-size: 14px;
    color: #757575;
    display: block;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    line-height: 25px;
    font-weight: 400;
}

ul.login_login {
    list-style: none;
}

ul.login_login li {
    display: inline-block;
}

ul.login_login li.rtlborder {
    border: 1px solid #ccc;
    margin-left: -4px;
    border-width: 0 0 0 1px;
}

ul.login_login button {
    padding: 0.40625rem 1.25rem;
    margin: .3125rem 1px;
    font-size: 16px;
    font-weight: bold;
    color: var(--theme-color);
    text-transform: capitalize;
    background: transparent;
    box-shadow: none;
    border: 0px;
}

button.loginasvendor {
    border: none;
    background: rgb(255, 255, 255);
}

.titles-vendor {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    margin-top: 10px;
}

.loginasvendor img {
    height: 55px;
    width: auto;
    max-width: unset;
}

.registerants {
    padding-top: 70px;
}

.login-heading button {
    border: none;
    background: rgb(255, 255, 255);
    padding: 0px 10px 0px;
    font-size: 20px
}

.login-heading {
    display: flex;
}

.login-heading h2 {
    font-size: 22px;
    margin-bottom: 0 !important;
    color: var(--heading-color);
    font-family: var(--heading-font);
    display: block;
    font-weight: 600;
    line-height: 1.4;
    padding-left: 12px;
    display: inline-block;
    position: relative;
    margin-left: 10px;
}

.login-heading h2 .shape {
    position: absolute;
    width: 72px;
    height: 32px;
    background-image: linear-gradient(90deg, rgba(15, 106, 255, .6705882352941176), rgba(249, 2, 227, .4392156862745098));
    left: 0;
    top: 0;
    margin: 0;
    line-height: 1;
}

p.forget_Account {
    width: fit-content;
    cursor: pointer;
    color: rgb(120, 120, 120);
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    text-align: center;
}

p.alredy_Account {
    color: rgb(243, 63, 85);
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 0px;
    font-weight: 700;
}

.socialLoginouter {
    margin-top: 20px;
    margin-bottom: 25px;
}

.google.login-google {
    margin-right: 10px;
}

.cmn-btn-auth {
    font-family: var(--heading-font);
    transition: .4s;
    border: 1px solid transparent;
    background-image: linear-gradient(90deg, #0f6aff, #f902e3);
    color: #fff;
    padding: 8px 34px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    border-radius: 8px;
    text-decoration: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.loginModalMyplo_Right label {
    margin-bottom: 11px;
    font-size: 16px;
    font-weight: 600;
    display: block;
    color: #000;
}

.logbtnss {
    appearance: none;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-overflow: ellipsis;
    font-family: var(--theme-fontfamily);
    color: #212529;
    width: auto;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    text-decoration: none;
    overflow: hidden;
    margin: 0px;
    background: var(--theme-color);
    transition: background-color 0.25s;
    padding: 0px 1.4rem;
    border-radius: 100em;
    text-transform: capitalize;
}

.myplo_errors {
    padding: 80px 0px;
}

.myplo_errors p {
    font-size: 1rem;
}

.myplo_errors h1 {
    font-size: 1.25rem;
    margin: 1em;
    font-weight: 400;
}


/* blog img */
.blog-item {
    position: relative;
    background-color: rgba(150, 144, 162, .06);
    padding: 15px;
}

.blog-item figure.image-holder {
    padding-bottom: 80%;
    position: relative;
}

.blog-item figure.image-holder img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.date-line {
    font-size: .9rem;
    color: #635d6f;
    font-weight: 500;
}

.blog-item h4,
.blog-item h4 a {
    font-weight: 700;
    color: #000;
    margin-top: 8px;
    margin-bottom: 10px;
}

.blog-item .button-text {
    color: #e71de1;
    font-weight: 700;
    font-size: 16px;
}

.blog-item .button-text i {
    margin-left: 5px;
}

.blog-detail-pag h1 {
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 12px;
}

.single-post-item {
    margin-top: 20px;
}

.contact-outer {
    background: #fff;
    box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    border-radius: 12px;
    padding: 20px;
    border-radius: 8px;
}

.single-post-item h3 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 25px;
}

.comments-box li {
    list-style: none;
}

.comments-box {
    margin-bottom: 20px;
}

.comments-box .comment-inner {
    background: #fff;
    box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    border-radius: 12px;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.comments-box .comment-inner {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 20px;
}

.comments-box .comment-thumb img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-top: 5px;
}

.comments-meta h4 {
    font-size: 20px;
    font-weight: 700;
    display: block;
}

.comment-area p {
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 10px;
}

.single-post-item textarea:focus {
    background-color: initial !important;
    box-shadow: none !important;
    border: 1px solid #ddd;
}

.single-post-item textarea {
    min-height: 100px;
    border: 1px solid #ddd;
    width: 100%;
    background: #fff;
    padding: 11px 19px;
    color: #667085;
    line-height: 25px;
    border-radius: 8px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 300;
    box-sizing: border-box
}

.blog-detail-pag p,
.comment-area p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

.blog-detail-pag ul {
    padding-left: 20px;
}

.blog-detail-pag h2 {
    font-size: 1.50rem;
    font-weight: bold;
}

/* product details */
.breadcrumbs_pro .breadcrumb-item+.breadcrumb-item::before {
    content: "\f054";
    font-family: "Font Awesome 6 Free";
    font-size: 14px;
    margin: 0 9px;
    color: rgb(102, 112, 133);
    /* padding: 0px; */
    display: block;
    float: none;
    font-weight: 600;
    display: inline-block;
    vertical-align: middle;
}

.breadcrumbs_pro .breadcrumb-item a:hover {
    color: var(--theme-color);
}

.breadcrumbs_pro .breadcrumb-item a {
    color: rgb(102, 112, 133);

}

.breadcrumbs_pro .breadcrumb-item {
    font-family: var(--body-font);
    color: rgb(102, 112, 133);
    display: flex;
    align-items: center;
    font-size: 16px;
    text-transform: capitalize;
    line-height: 1;
    font-weight: 400;
}

.prodcuts_Details_page {
    padding: 50px 0px;
}

.map_prodcts h2 svg {
    color: rgb(148, 148, 148);
    fill: currentColor;
    width: 27px;
}

.map_prodcts {
    padding: 50px 0px 60px;
}

.map_prodcts h2 {
    margin-top: 30px;
    font-size: 17px;
    color: rgb(148, 148, 148);
    margin-bottom: 10px;
    font-weight: bold;
}

.map_prodcts_inner {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 10px 1px;
    height: 320px;
    border-radius: 5px;
}

.map_prodcts_inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Main Slider */
.product-slider .main-slider .item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Thumbnail Slider */
.product-slider .thumbnail-slider {
    display: flex;
    align-items: center;
}

.product-slider .thumbnail-slider .thumb-item {
    cursor: pointer;
    padding: 5px;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.product-slider .thumbnail-slider .thumb-item.active {
    border-color: #007bff;
}

.product-slider .thumbnail-slider .thumb-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Navigation Arrows */
.product-slider .thumbnail-slider .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.product-slider .thumbnail-slider .owl-nav .owl-prev,
.product-slider .thumbnail-slider .owl-nav .owl-next {
    background: #007bff;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.product-slider .thumbnail-slider .owl-nav .owl-prev:hover,
.product-slider .thumbnail-slider .owl-nav .owl-next:hover {
    background: #0056b3;
}

.prodcuts_Details_page .mainSwiper img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 0px;
}

.prodcuts_Details_page .thumbsSwiper img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
}

.prodcuts_Details_page .thumbsSwiper {
    padding-top: 10px;
}

.prodcuts_Details_inner {
    padding: 30px 0px 0px;
}

.prodcuts_Details_inner .swiper-button-prev {
    left: 0px;
}

.prodcuts_Details_inner .swiper-button-next {
    right: 0px;
}

.prodcuts_Details_inner .swiper-button-prev.swiper-button-disabled,
.prodcuts_Details_inner .swiper-button-next.swiper-button-disabled {
    opacity: 1;
}

.prodcuts_Details_inner .swiper-button-prev,
.prodcuts_Details_inner .swiper-button-next {
    width: 40px;
    color: #fff;
    background: rgb(187 187 187);
    height: 40px;
    border-radius: 100px;
    text-align: center;
    line-height: 40px;
    font-weight: 600;
}

.prodcuts_Details_inner .swiper-button-prev:after,
.prodcuts_Details_inner .swiper-button-next:after {
    font-size: 20px;

}

.prodcuts_Details_inner .proDescription p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

.prodcuts_Details_inner .proDescription h1 {
    transition: .4s;
    font-family: var(--heading-font);
    color: var(--heading-color);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.prodcuts_Details_inner .proDescription {
    padding: 25px 20px 20px;
    background: #fff;
    box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    border-radius: 0 0 12px 12px;
    margin-top: 10px;
}

.cmn-btn-outline2 {
    font-family: Ubuntu, sans-serif;
    font-family: var(--heading-font);
    border: 1px solid #d0d5dd;
    color: #667085;
    font-size: 15px;
    font-weight: 700;
    text-transform: normal;
    padding: 14px 15px;
    align-items: center;
    text-align: center;
    -moz-user-select: none;
    cursor: pointer;
    display: inline-block;
    transition: color .4s linear;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: 0 0;
    z-index: 1;
    width: 100%;
    min-width: 200px;
}

.cmn-btn-outline2:before {
    border: 1px solid transparent;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 101%;
    height: 101%;
    background-image: linear-gradient(90deg, #0f6aff, #f902e3);
    z-index: -1;
    transition: transform .5s;
    transition-timing-function: ease;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(.5, 1.6, .4, .7);
    transform: scaleX(0);
    border-radius: 0;
}

.cmn-btn-outline2:hover:before {
    transform: scaleX(1);
    border: 1px solid transparent;
}

.cmn-btn-outline2:hover {
    color: #fff;
}

.btn-wrapper {
    display: flex;
    grid-column-gap: 13px;
    column-gap: 13px;
    grid-row-gap: 15px;
    row-gap: 15px;
}

.details_oUter {
    background: rgb(255, 255, 255);
    box-shadow: rgba(26, 40, 68, 0.06) 0px 1px 80px 12px;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
}

.details_oUter .cls_cateprolist {
    padding-bottom: 15px;
}

.details_oUter .cls_cateprolist ul li {
    font-size: 12px;
    color: rgb(35, 35, 35);
    font-weight: 400;
    text-transform: uppercase;
    margin: 0px 0px 8px;
}

.details_oUter .cls_cateprolist ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.details_oUter h3 span {
    font-size: 20px;
    margin-left: 4px;
    color: var(--theme-color);
    font-weight: bold;
}

.details_oUter h2,
.details_oUter h3 {
    font-family: var(--heading-font);
    margin-bottom: 9px;
    line-height: 1.5;
    color: var(--heading-color);
    font-weight: bold;
    display: flex;
    text-transform: capitalize;
    -webkit-box-align: center;
    align-items: center;
    font-size: 20px;
}

.flex-categries-pri {
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 0px 5px;
}

.nn_pro_share ul .dropdown-item:last-child {
    border-bottom: 0;
}

.nn_pro_share ul li .dropdown-toggle {
    margin-left: 5px;
}

.nn_pro_share ul li .dropdown-toggle:hover {
    background: transparent;
    color: #000;
}

.nn_pro_share ul li .dropdown-toggle::after {
    display: none;
}

.nn_pro_share ul li button.active {
    color: var(--theme-color);
}

.nn_pro_share ul li button svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
    margin-top: -4px;
    display: inline-block;
    font-size: 22px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
}

.nn_pro_share ul li button {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    line-height: normal;
    padding-right: 0px;
    border: 0px;
    font-size: 22px;
    background: transparent;
    color: #000;
}

.nn_pro_share ul {
    margin: 0px;
    gap: 16px;
    padding: 0px;
    padding-right: 20px;
    list-style: none;
    display: flex;
    align-items: center;
}

.discard-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.discard-popup .popup-content {
    position: relative;
    background: #fff;
    border-radius: 4px;
    width: 30%;
    padding: 15px 15px 45px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: rgb(255, 255, 255);
    overflow: auto;
}

.discard-popup .social-buttons {
    display: flex;
    /* justify-content: space-around; */
    gap: 20px;
    justify-content: center;
}


.discard-popup .facebook-btn {
    background-color: rgb(63, 91, 150);
    padding: 0px;
    border-radius: 32px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 100px;
    width: 44px;
    height: 44px;
}

.discard-popup .whatsapp-btn svg {
    fill: rgb(255, 255, 255);
    width: 40px;
    height: 40px;
}

.discard-popup .facebook-btn svg {
    fill: rgb(255, 255, 255);
    width: 24px;
    height: 24px;
}

.discard-popup .whatsapp-btn {
    background-color: rgb(4, 167, 78) !important;
    padding: 0px;
    border-radius: 32px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 100px;
    width: 44px;
    height: 44px;
}

.discard-popup .close-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;
}

.discard-popup .close-btn button {
    background: #fff;
    color: var(--theme-color);
    border: none;
    padding: 0px;
    font-size: 24px;
    border-radius: 50%;
}

.discard-popup .popup-header h3 {
    display: block;
    /* padding-top: 15px; */
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
}

.discard-popup .input-group svg {
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
    width: 16px;
}

.discard-popup .input-group button {
    box-shadow: none;
    outline: none;
    color: var(--theme-color);
    font-size: 14px;
    border: none;
    background: transparent;
}

.prodcuts_Details_right label {
    margin-bottom: 11px;
    font-size: 16px;
    font-weight: 600;
    display: block;
    color: #000;
}

.discard-popup .input-group input,
.prodcuts_Details_right input.form-control {
    border: 1px solid #ddd;
    width: 100%;
    font-weight: 300;
    height: 50px;
    background: #fff;
    padding: 11px 19px;
    color: #667085;
    line-height: 25px;
    border-radius: 8px;
    margin-bottom: 0;
    font-size: 16px;
    box-sizing: border-box;
}

.discard-popup .input-group {
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
    gap: 0px;
    box-shadow: rgba(46, 60, 73, 0.05) 0px 2px 2px 0px;
    margin-bottom: 24px;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 6px;
    padding: 5px 16px;
}

/* Myplo_orderview */

.Myplo_orderview {
    background-color: rgb(246, 245, 247);
    padding: 30px 0px 0px;
    display: inline-block;
    width: 100%;
}

.Myplo_orderview p {
    font-size: 18px;
}

.Myplo_orderview h1 {
    font-size: 27px;
    font-weight: bold;
    margin: 0px;
}

.myplo_addressbtn1 {
    background-color: var(--theme-color);
    font-size: 16px;
    padding: 8px 30px;
    border-radius: 3px;
    color: #fff;
    border: 0px;
    font-weight: 600;
}

.modal .btn {
    border-radius: 3px;

}

.myplo_addressbtn {
    background-color: var(--theme-color);
    font-size: 16px;
    padding: 8px 30px;
    border-radius: 3px;
    margin-bottom: 35px;
    color: #212529;
    border: 0px;
    font-weight: 300;

}

.myplo_address_flex h2 {
    font-size: 22px;
    margin: 0px;
    font-weight: bold;
    width: calc(100% - 215px);
    padding: 15px 10px;
    cursor: pointer;
}

.myplo_address_flex {
    align-items: center;
    border-radius: 3px;
    background: rgb(255, 255, 255);
    height: auto;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 32px;
    justify-content: space-between;
    padding: 0px 10px;
}

.Step_address-empty {
    text-align: center;
}

.Step_address-empty h3 {
    font-size: 22px;
    font-weight: bold;
    padding: 35px 0px 20px;
}

.myplo_addresslist.active {
    border: 4px solid var(--theme-color);
    position: relative;
}

.myplo_addresslist h3 {
    margin-top: 15px;
    font-size: 20px
}

.myplo_addresslist p {
    font-weight: 300;
    margin: 0px;
    font-size: 16px;
}

.myplo_addresslist {
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 5px;
    border: 4px solid rgb(255, 255, 255);
    min-height: 360px;
    cursor: pointer;
    position: relative;
    color: rgb(0, 0, 0) !important;
}

.myplo_addresslist .cls_editdel img {
    margin-left: 8px;
    cursor: pointer;
    width: 25px;
}

.myplo_addresslist .cls_editdel {
    text-align: right;
    padding: 10px 30px;
}

.myplo_address_flex span {
    font-size: 16px;
    color: rgb(34, 34, 34);
    display: block;
}

.sec_one_address .cls_orderimg {
    height: 156px;
    flex-grow: 0;
    max-width: 16.66667%;
    flex-basis: 16.66667%;
    border-radius: 5px;
    border: 1px solid rgb(221, 221, 221);
    background: rgb(255, 255, 255);
    text-align: center;
    overflow: hidden;
}

.sec_one_address .cls_orderimg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: rgb(255, 255, 255);
}

.cls_ordersummery label {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    color: #212529;
    margin-bottom: 11px;
}

.cls_ordersummery p {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    color: #212529;
}

.cls_ordertxt {
    font-size: 1rem;
    font-weight: 600;
    color: #212529;
}

.cls_ordersummery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.cls_ordertxt {
    padding: 0px 10px;
    flex-grow: 0;
    max-width: 58.33333%;
    flex-basis: 58.33333%;
}

.cls_orderprice {
    font-size: 1rem;
    font-weight: 600;
    color: #212529;
    text-align: right;
    padding: 0px 10px;
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
}

.delete_modal .nn_discard_btn .btn1 {
    background-color: var(--theme-color);
    color: #212529;

    border: 1px solid var(--theme-color);
}

.delete_modal .nn_discard_btn .btn2 {
    color: var(--theme-color);
    border: 1px solid var(--theme-color);
    background-color: rgb(255, 255, 255);
}

.delete_modal .nn_discard_btn {
    text-align: center;
}

.delete_modal .nn_discard_btn .btn1,
.delete_modal .nn_discard_btn .btn2 {
    border-radius: 24px;
    padding: 8px 20px;
    margin: 10px;
    line-height: 1;
    font-size: 16px;
    font-weight: bold;
    min-height: 40px;
    letter-spacing: 0.2px;
}

.delete_modal .modal-dialog .modal-content .modal-body {
    padding: 20px;
}

.modal-content {
    border-radius: 4px;
    border: 0px;

}

.delete_modal .modal-dialog {
    max-width: 400px;
}

.delete_modal .modal-dialog h4 {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 1rem;
    text-align: center;
}

.Add_addres_modal .modal-body label {
    margin-bottom: 11px;
    font-size: 16px;
    font-weight: 600;
    display: block;
    color: #000;
}

.Add_addres_modal .modal-body .MuiFormControl-root .MuiInputBase-colorPrimary::before {
    border-color: rgb(210, 210, 210)
}

.Add_addres_modal .modal-body .MuiFormControl-root .Mui-focused.MuiInputBase-colorPrimary::before,
.Add_addres_modal .modal-body .MuiFormControl-root .MuiInputBase-colorPrimary::after {
    border-color: rgb(231 29 225);
}

.Add_addres_modal .modal-body input {
    background: #fff;
    font-size: 14px;
    padding: 6px 8px 7px;
    border-top: 1px solid rgb(210, 210, 210);
    border-right: 1px solid rgb(210, 210, 210);
    border-left: 1px solid rgb(210, 210, 210);
    border-radius: 5px;
}

.Add_addres_modal .modal-header .close {
    background: transparent;
    border: none;
    line-height: 1;
    color: var(--theme-color);
    font-size: 30px;
    margin-right: 20px;
    font-weight: 300;

}

.Add_addres_modal .modal-header h3 {
    font-weight: 400;
}

.Add_addres_modal .modal-header {
    justify-content: space-between;
}


/* Custom Modal Styles */
.paymentpaypalwind {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(63 63 63);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

/* .payment_details{
    position: relative;
  } */
.paymentpaypalwind .payment_wrapper {
    width: 560px;
    position: relative;
    text-align: center;
    border-radius: 10px;
    background: #fff;
}

.paymentpaypalwind .payment_head {
    padding: 10px 15px;
}

.paymentpaypalwind .payment_head h3,
.paymentpaypalwind .payment_head p {
    color: #000;
    font-weight: 600;
    font-size: 1.5em;
    margin: 0;
    padding: 10px 0;
}

.paymentpaypalwind .payment_body {
    background-color: #f6f5f7;
    padding: 20px;
    max-height: 550px;
    overflow: auto;
}

.paymentpaypalwind .payment_body_cont {
    border-radius: 0 0 10px 10px;
}

.paymentpaypalwind .cxlosepay {
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
    background: #e71de1;
    color: #fff;
    border-radius: 0 0 0 0;
    height: 35px;
    border: 0px;
}

.paymentpaypalwind button.cxlosepay svg {
    width: 25px;
    padding: 0;
    fill: #fff;
}

.paymentpaypalwind .pay_now {
    margin: 25px auto;
}

.paymentpaypalwind .payment_body {
    border-radius: 0 0 10px 10px;
}

.paymentpaypalwind .pay_now_btn {
    background-color: var(--theme-color);
    color: var(--subtheme-color);
    font-size: 16px;
    padding: 8px 30px;
    font-weight: 500;
    border-radius: 10px;
    border: none;
}

.paymentpaypalwind .payment_head h3 {
    margin-right: 5px;
}

.nn_prochatbtn1 {
    display: flex;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px 0px;
    border-radius: 10px;
    width: auto;
    margin-right: 15px;
}

.nn_prochatbtn1 a {
    position: relative;
    width: 60px;
}

.butonChat a {
    width: 90px !important;
}

.nn_prochatbtn1 a img {
    padding: 5px;
    border-radius: 100%;
}

.butonChat img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
}

.nn_prochatbtn1 a::after {
    content: "";
    position: absolute;
    right: -24px;
    top: 16px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent transparent rgb(255, 255, 255);
}


.chatname-bg {
    width: 100%;
    color: #fff;
    padding: 0 10px;
    font-weight: 700;
    background-image: linear-gradient(90deg, #0f6aff, #f902e3) !important;
    border: 0px;
}

.chatname-bg:hover {
    background-image: linear-gradient(90deg, #f902e3, #0f6aff) !important;
}

.featureTittle a{
    color: var(--heading-color);
    font-weight: 500;

}
.featureTittle {
    font-family: var(--heading-font);
    margin-bottom: 2px;
    line-height: 1.5;
    color: var(--heading-color);
    font-weight: 500;
    font-size: 22px;
    display: block;
}

.featureCap {
    font-family: var(--heading-font);
    font-size: 15px;
    color: var(--heading-font);
    margin-bottom: 2px;
}

.featureCap .subCap {
    font-family: var(--heading-font);
    color: #524eb7;
    font-weight: 400;
}

/* Seller Details */
.Seller_edit_promain {
    background-color: rgb(246, 245, 247);
    margin-top: 0px;
    padding: 50px 0px;
}

.seller_tabs_nav {
    text-align: center;
    position: relative;
    background: #fff;
}

.seller_tabs_nav h4 {
    font-size: 20px;
    font-weight: bold;
}

.seller_edit_pro_img img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    text-align: center;
    margin: 0px auto;
    border-radius: 50px;
    border: 1px solid rgb(221, 221, 221);
}

.seller_edit_icons .dropdown-toggle::after {
    display: none;
}

.seller_edit_icons button.dropdown-toggle.btn.btn-primary {
    background: transparent;
    border: 0px;
    color: rgb(117, 117, 117);
    font-size: 24px;
    padding: 0px;

}

.seller_profile-img {
    padding: 20px;
}

.seller_edit_icons {
    position: absolute;
    right: 20px;
    top: 20px;
}

.nav-fill.nav.nav-tabs {
    display: block;
    width: 100%;
}

.nav-fill.nav.nav-tabs a {
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    color: rgb(0, 0, 0);
    text-align: left !important;
    border-top: 1px solid rgb(238, 238, 238) !important;
}

.nav-fill.nav.nav-tabs .nav-item a.active,
.nav-fill.nav.nav-tabs .nav-item a:hover,
.nav-fill.nav.nav-tabs .nav-item a:focus {
    color: var(--theme-color);
    background-color: rgb(251, 251, 251);
}

.selller_edit_sellpromain {
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    padding: 20px 0px;
}

.seller_edit_sellpro {
    padding: 20px;

}

.selller_edit_sellpromain .nn_edit_proname {
    padding: 5px 20px 15px;
    display: block;
    border-bottom: 1px solid rgb(238, 238, 238);
    font-size: 20px;
    font-weight: bold;
}

.seller_edit_sellpro_inner .product-boxes_inner {
    padding: 15px;
    margin-top: 0px;
    border-radius: 0px 0px 8px 8px;
    min-height: unset;
    height: auto;
}

.seller_edit_sellpro_inner .product-boxes img {
    height: 200px;
    width: 100%;
}

.seller_edit_sellpro_inner .product-boxes_inner h4 {
    color: rgb(44, 44, 44);
    font-weight: bold;
    margin-top: 10px;
    white-space: nowrap;
    width: 100%;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.seller_edit_sellpro_inner .product-boxes_inner p {
    color: rgb(117, 117, 117);
    line-height: 1.2em;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
}

.seller_edit_sellpro {
    min-height: 500px;
}

.nn_empty_tab p span {
    color: var(--theme-color);
    font-size: 18px;
    font-weight: 600;
}

.nn_empty_tab p {
    font-size: 16px;
    color: rgb(88, 86, 86);
    text-align: center;
}

.seller_edit_icons .dropdown-menu.show .dropdown-item:focus,
.seller_edit_icons .dropdown-menu.show .dropdown-item:hover {
    color: rgb(88, 86, 86);
    text-decoration: none;
    background-color: rgb(248, 249, 250);
}

.seller_edit_icons .dropdown-menu.show .dropdown-item {
    padding: 5px 10px;
    font-weight: 500;
    font-size: 15px;
    border-bottom: 0px;
}

.seller_edit_icons .dropdown-toggle {
    white-space: nowrap;
}

.seller_edit_icons .dropdown-menu.show {
    /* right: auto !important; */
    border-radius: .25rem;
    transform: unset !important;
    inset: unset !important;
    top: 40px !important;
    left: -150px !important;
}

.seller_profile-img ul {
    padding-left: 0px;
    list-style: none;
}

.seller_profile-img ul li i {
    margin-right: 6px;
}

.seller_profile-img ul li {
    margin-bottom: 2px;
}

.seller_edit_sellpro .form-control:focus {
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.car-gigss img {
    height: 100px;
    width: auto;
    margin-top: 25px;
    margin-bottom: 15px;
}

div#nav-tab .active .car-gigss {
    border: 2px solid #e71de1;
}

.car-gigss {
    text-align: center;
    background: #fff;
    box-shadow: 0 0 56px 10px rgba(0, 0, 0, .07);
    border-radius: 20px;
    padding: 10px 20px 20px;
    border: 2px solid #fff;
}

.car-gigss h5 {
    margin-bottom: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000;
}

.car-gigss p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #807b7b;
    margin-bottom: 0;
}

.seller_edit_sellpro .nav-fill.nav.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
}

.seller_edit_sellpro .nav-fill.nav.nav-tabs {
    gap: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 0px;

}

.seller_edit_sellpro .nav-fill.nav.nav-tabs .nav-link {
    padding: 0px !important;
    border: 0px !important;
    border-radius: 0px;
}

.seller_edit_sellpro .nav-link.active .car-gigss {
    border: 2px solid #e71de1;
}

.nn_order_details-pay .table td,
.nn_order_details-pay .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.seller_edit_sellpro .card {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    overflow: hidden;
}

.gis-services .product-boxes_inner p {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333;
    margin-bottom: 15px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.product-boxes_inner .d-flex .fa-star {
    margin-right: 2px;
}

.nn_order_status {
    padding: 15px;
    border-bottom: 1px solid rgb(221, 221, 221);
}

section.nn_edit_prosection {
    padding: 15px;
}

.nn_edit_prosection .nn_order_title {
    text-align: center;
    font-size: 14px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
}

.nn_edit_prosection .nn_order_title h6 {
    font-weight: bold;
}

.nn_edit_prosection .nn_order_title span {
    color: rgb(66, 66, 66);
}

.nn_order_produ1 {
    font-size: 16px;
    color: rgb(0, 0, 0);
    display: inline-block;
}

.nn_order_derails .nn_order_id p {
    border: 1px solid rgb(0, 0, 0);
    padding: 5px 15px;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;
    min-width: 145px;
}

.nn_order_derails .nn_order_cancel button,
.nn_order_derails button {
    background-color: rgb(70, 78, 85);
    color: rgb(255, 255, 255);
    padding: 8px 20px;
    border-radius: 3px;
    font-size: 14px;
    min-width: 145px;
}

.nn_edit_allproducts .nn_edit_orderproctn {
    background-color: rgb(241, 243, 245);
    width: 100%;
    margin-bottom: 20px;
}

.imgwithtitle img {
    width: 34px;
    height: 34px;
    border-radius: 100px;
    object-fit: cover;
    object-position: center;
    margin-right: 5px;
}

td.cls_payout_options button {
    border: none;
    background: rgb(255, 255, 255);
}

.table td,
.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.Add_addres_modal .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.Add_addres_modal .react-datepicker-wrapper {
    width: 100%;
}

.Add_addres_modal .nn_form_group:focus {
    outline: none;
    transform: scaleX(1);
    border-bottom: 2px solid var(--theme-color) !important;
}

.Add_addres_modal .nn_form_group {
    background: #fff;
    font-size: 14px;
    padding: 6px 8px 7px;
    border-top: 1px solid rgb(210, 210, 210);
    border-right: 1px solid rgb(210, 210, 210);
    border-left: 1px solid rgb(210, 210, 210);
    border-radius: 5px;
    border: 1px solid rgb(210, 210, 210);
}

.Add_addres_modal .form-select:focus {
    border-color: rgb(210, 210, 210);
    border-bottom: 2px solid var(--theme-color) !important;

    outline: 0;
    transform: scaleX(1);
    box-shadow: unset;
}

.nn_edit_profileicon svg {
    fill: var(--theme-color);
    font-size: 14px;
    width: 18px;
    height: 18px;
}

.seller_edit_pro_img .nn_edit_profileicon {
    position: absolute;
    top: 20px;
    right: 37%;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) -1px 3px 10px 0px;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    display: inline;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    line-height: 0px;
}

.seller_profile-img i.fa-star {
    color: rgb(164, 164, 164);
}

.seller_profile-img i.fa-star.active {
    color: rgb(233, 147, 24);
}


.border-topB {
    border-top: 1px solid #eee;
    padding: 15px 0 0;
}

/* chat  */
.chat_flex_con {
    width: 100%;
    height: 75vh;
    display: flex;
    box-shadow: rgba(204, 204, 204, 0.8) 0px 0px 4px;
    /* border-radius: 10px; */
    border-top: 1px solid rgb(221, 221, 221);
}

.chat_flex_con_left {
    width: 35%;
    border-right: 2px solid rgb(238, 238, 238);
    background-color: rgb(255, 255, 255);
    height: 100%;
    overflow-y: auto;
}

.chat_flex_con_left .adminadd {
    padding: 15px;
    text-align: center;
}

.rtlechatuimg_flex {
    padding: 15px 10px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    cursor: pointer;
    font-family: var(--theme-fontfamily);
}

.nn_chat_proctn img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
}

.rtlechatuimg_flex.active_chats {
    background-color: rgb(243, 245, 246);
    border-left: 5px solid var(--theme-color);
    margin-right: 5px;
}

.nn_chat_proctn {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.chat_titles {
    padding-left: 15px;
}

.time-date_chat h6 {
    font-size: 14px;
    margin-bottom: 0px;
    background-color: #e71de1;
    color: #fff;
    padding: 2px 6px;
    border-radius: 100%;
}

.chat_titles h2 {
    color: rgb(66, 66, 66);
    font-size: 15px;
    font-weight: bold;
    text-transform: capitalize;
    word-break: break-all;
    margin-bottom: 5px;
}

.nn_buying_color {
    color: rgb(245, 74, 97);
    font-weight: 600;
    display: flex;
    font-size: 13px;
}

.chat_flex_con_right {
    width: 68%;
    font-family: var(--theme-fontfamily);
    position: relative;
}

.chatrtprofile_head {
    width: 100%;
    height: 80px;
    padding: 10px 15px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

.backarrow_chat {
    padding: 0px 5px;
}

.backarrow_chat button {
    border: none;
    background: rgb(255, 255, 255);
}

.chat_flex_con_right .nn_chatnm {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px;
}

.nn_selling_color {
    color: rgb(52, 202, 52);
    font-weight: 600;
    display: flex;
    font-size: 13px;
}

.chat_flex_con_right .chatrt_msg_body {
    display: flex;
    flex: 1 1 0%;
    padding: 25px 0px 10px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    flex-direction: column;
    overscroll-behavior-y: contain;
    height: calc(100% - 223.01px);
}

.chatrt_msg_footer {
    padding: 0px;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.chatrt_msg_footer .nn_chatbtmain {
    background: rgb(255, 255, 255);
}

.chatrt_msg_footer .nn_chatbtctn {
    display: inline-flex;

}

.new-messages-btn {
    background: transparent;
    outline: none;
    border: none;
}

.chatrt_msg_footer .nn_chatbtn {
    background-color: var(--theme-color);
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 5px 10px;
    margin-right: 10px;
}

.chatrt_msg_footer .nn_chatinput textarea {
    padding: 5px 10px;
    color: rgb(117, 117, 117);
    background-color: rgb(244, 244, 244);
    border: none;
    outline: none;
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    height: 70px;
    resize: none;
   
}

.chatrt_msg_footer .nn_chatinput {
    padding: 0px 15px 15px;
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0px;
}


.chatrt_msg_footer .nn_chatsendbtn button {
    border: none;
    background: rgb(255, 255, 255);
    padding-right: 0px;
}

.chatrt_msg_footer .nn_chatsendbtn button img {
    background: var(--theme-color);
    padding: 7px;
    margin-left: 7px;
    border-radius: 3px;
    width: 35px;
    height: 35px;
    vertical-align: baseline;
}

.chatrt_msg_body .nn_leftpart {
    width: auto;
    max-width: 60%;
    float: right;
    padding: 0px 12px;
    text-align: left;
    word-break: break-word;
}

.chatrt_msg_body .left .nn_leftpart {
    float: left !important;
}

.left .nortltrans {
    background-color: #116aff !important;
}

.chatrt_msg_body .nn_leftpart .nortltrans {
    background-color: var(--theme-color-hvr);
    padding: 8px 12px;
    word-break: break-all;
    border-radius: 8px;
    color: rgb(255, 255, 255);
}

.chatrt_msg_body .nn_leftpart .dateseen {
    font-size: 11px;
    color: rgb(97, 97, 97);
    font-weight: 400;
}

.con_chat_title a {
    font-size: 24px;
}

.con_chat_title a {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 5px 20px;
    color: rgb(34, 34, 34);

}

.con_chat_title h1 {
    font-size: 25px;
    font-weight: bold;
    text-transform: capitalize;
    padding: 15px;
    margin-bottom: 0px;
    color: rgb(34, 34, 34);
}

.myplo_chat_page {
    padding: 40px 0px;
    background-color: rgb(246, 245, 247);
}





/* admin css add seprate file */
.location-cutome-input::placeholder {
    color: #b9bec5;
}

.location-cutome-input:focus {
    border: 1px solid #EB08E4;
    outline: 0px;
}

.location-cutome-input {
    background: #F2F2F2;
    border-radius: 10px;
    border-color: #F2F2F2;
    font: inherit;
    padding: 14px 20px;
    line-height: 21px;
    border: 1px solid #F2F2F2;
    display: block;
    width: 100%;
}



.boxtbale {
    padding: 20px;
}

/* loader css seprate */
/* Loader container */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure it's on top of everything */
}

/* Spinner animation */
.loader {
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    /* Light border */
    border-top: 5px solid #f902e3;
    /* White top border for the spin effect */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Keyframes for spin animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.delete_modal .nn_discard_btn .btn1 {
    background-color: #e71de1;
    ;
    color: #fff;
    border: 1px solid #e71de1;
    ;
}

.delete_modal .nn_discard_btn .btn1,
.delete_modal .nn_discard_btn .btn2 {
    border-radius: 24px;
    padding: 8px 20px;
    margin: 10px;
    line-height: 1;
    font-size: 16px;
    font-weight: bold;
    min-height: 40px;
    letter-spacing: 0.2px;
}

.delete_modal .modal-dialog {
    max-width: 400px;
}

.delete_modal .modal-dialog .modal-content .modal-body {
    padding: 20px;
}

.delete_modal .nn_discard_btn {
    text-align: center;
}

.delete_modal .nn_discard_btn .btn2 {
    color: #e71de1;
    border: 1px solid #e71de1;
    background-color: rgb(255, 255, 255);
}

.delete_modal .modal-dialog h4 {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 1rem;
    text-align: center;
}

.modal.fade.show.delete_modal {
    z-index: 9999;
}

.but-groups-action {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.deletebtn-small {
    border: 0;
    color: #dc3545;
    border: 1px solid #dc3545;
    background: #ffbfc5;
    border-radius: 4px;
    padding: 2px 2px;
    font-size: 20px;
    min-width: 30px;
    min-height: 30px;
}

.deletebtn-small:hover {
    background-color: #dc3545;
    color: white;
}

.deletebtn-small svg,
.editbtn-small svg {
    width: 20px;
    height: 20px;
}

.editbtn-small {
    font-size: 20px;
    border: 0px;
    background: rgb(191 214 255);
    color: #1368fe;
    border: 1px solid #1368fe;
    border-radius: 4px;
    padding: 2px 2px;
    min-width: 30px;
    min-height: 30px;
}

.editbtn-small:hover {
    background-color: #1368fe;
    color: white;

}

.nn_empty_tab p {
    font-size: 16px;
    color: rgb(88, 86, 86);
    text-align: center;
}

.nn_empty_tab p span {
    color: #e71de1;
    font-size: 18px;
    font-weight: 600;
}


.upload_file {

    background-color: #ffffff;
    border: 1px solid #ed07e4;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    display: inline-block;
    min-height: 258px;
}

.my_location {
    position: relative;
}

.my_location .autocomplete-dropdown-container {
    position: absolute;
    top: 51px;
    width: 100%;
    z-index: 999;
}

.Popup-cartopen .MuiInputBase-formControl:after {

    border-bottom: 2px solid #e71de1;
}

.location_relate {

    position: relative;
}

.hover_my {
    position: absolute;
    top: 23px;
    z-index: 99;
    width: 100%;
    padding: 0px 30px;
}

.hover_my .location-cutome-input {
    height: 32px !important;
    padding: 0px 12px !important;
    border-radius: 3px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px !important;
    font-size: 14px !important;
    outline: none !important;
    background-color: white !important;
    position: relative;

}
.location_input.my_location  .autocomplete-dropdown-container {
    /* border-top: 1px solid #d9d9d9; */
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    background-color: #fff;
}

.Popup-cartopen .hover_my .autocomplete-dropdown-container {
    background-color: #fff;
    position: absolute !important;
    z-index: 1000;
    width: calc(100% - 60px);
    border-radius: 2px;
    /* border-top: 1px solid #d9d9d9; */
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    overflow: hidden;
    top: 33px;
    left: 30px;
}
.hover_my .location-cutome-input:focus {
    border: 1px solid transparent;
}

.input_mui_box .MuiSelect-select {}


.input_mui_box fieldset {
    border-radius: 15px;
}


.dot-bricks {
    position: relative;
    top: 8px;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff;
    animation: dot-bricks 2s infinite ease;
}

@keyframes dot-bricks {
    0% {
        box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff;
    }

    8.333% {
        box-shadow: 10007px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff;
    }

    16.667% {
        box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff, 10007px 0 0 0 #9880ff;
    }

    25% {
        box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff;
    }

    33.333% {
        box-shadow: 10007px 0 0 0 #9880ff, 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff;
    }

    41.667% {
        box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff;
    }

    50% {
        box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff;
    }

    58.333% {
        box-shadow: 9991px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff;
    }

    66.666% {
        box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff, 9991px -16px 0 0 #9880ff;
    }

    75% {
        box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff;
    }

    83.333% {
        box-shadow: 9991px -16px 0 0 #9880ff, 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff;
    }

    91.667% {
        box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff;
    }

    100% {
        box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff;
    }
}


/* edit modal css */
.imageUpload {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 2px dashed rgb(170, 170, 170);
    border-radius: 5px;
    position: relative;
    flex-wrap: nowrap;
    text-align: left;
    margin-bottom: 15px;
    gap: 10px;
}

.edit_dialog_modal nav .nav.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    display: flex;
}

.edit_dialog_modal .nav-tabs .nav-link {
    width: 50%;
    font-size: 16px;
    border: 0px;
    border-bottom: 0px solid !important;
    color: rgb(51, 51, 51);
}

.edit_dialog_modal .tab-content.overall-mg {
    margin: 20px 0px;
}

.edit_dialog_modal .nav-tabs .nav-link.active {
    color: var(--theme-color);
    font-size: 16px;
    border: 0px;
    border-bottom: 2px solid !important;
}

.form-editprofile .MuiFormControl-root svg {
    width: 24px;
}

.form-editprofile .MuiFormControl-root {
    margin-bottom: 20px;
    width: 100%;
}

.form-editprofile .MuiFormControl-root label {
    color: #AAAAAA !important;
    font-size: 14px;
    line-height: 1.42857;
}

.imageUpload span {
    text-align: left;
    color: rgb(117, 117, 117);
    font-weight: bold;
    font-size: 14px;
}

.imageUpload span:last-child {
    font-weight: normal;
}

.cls_round img {
    border-radius: 100px;
    height: 70px;
    width: 70px;
    object-fit: cover;
}

.logbtnss-edit {
    margin-bottom: 5px;
    text-align: center;
    text-overflow: ellipsis;
    font-family: var(--theme-fontfamily);
    color: white;
    width: auto;
    line-height: 46px;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    text-decoration: none;
    overflow: hidden;
    background: var(--theme-color);
    transition: background-color 0.25s;
    padding: 0px 1.4rem;
    border-radius: 100em;
    text-transform: capitalize;
    border: 0px;
    width: 100%;
}

.log_out {
    font-weight: bold;
    line-height: 46px;
    font-size: 16px;
    font-weight: bold;
    color: var(--theme-color);
    text-transform: capitalize;
}

.error-text {
    color: red;
}

/* new css add 09-01-2025 */
.search-blockpopup {
    width: 100%;
    left: 0px;
    border-radius: 10px;
    position: absolute;
    background: #fff;
    z-index: 99;
    box-shadow: 0 10px 8px rgba(0, 0, 0, 0.04), 0 4px 3px rgba(0, 0, 0, 0.1);
    top: 100%;
    margin-top: 0px;
    opacity: 1;
    visibility: visible;
    padding: 15px;
    transition: opacity 0.5s ease, margin-top 0.5s ease;
    min-width: 400px;
    border: 1px solid #ed08e5;
}

.search-box {
    position: relative;
}

button.btn.location-button {
    border: 1px solid #ddd;
    width: 100%;
    height: 50px;
    background: #fff;
    padding: 11px 12px;
    color: #667085;
    line-height: 25px;
    width: 130px;
    border-radius: 8px;
    margin-bottom: 0;
    font-size: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.location-icon-iputer input:focus {
    outline: none;
}

.location-icon-iputer input {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    border: 0px;
    color: #000 !important;
}

.location-icon-iputer {
    border: 1px solid #ddd;
    width: 100%;
    height: 50px;
    background: #fff;
    padding: 8px 10px;
    color: #667085;
    line-height: 25px;
    border-radius: 8px;
    margin-bottom: 0;
    font-size: 16px;
    white-space: nowrap;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 10px;
}

.search-blockpopup ul li button,
.search-blockpopup ul li a {
    color: #333;
    font-size: 14px;
    padding: 8px 0px;
    display: block;
    white-space: nowrap;
    border: 0px;
    background: transparent;
    text-overflow: ellipsis;
    overflow: hidden;
}

.search-blockpopup ul li:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
}

.search-blockpopup ul li {
    overflow: hidden;
}

.set-cureent-location {
    color: var(--theme-color);
    border-bottom: 1px solid #b4b4b4;
    display: block;
    margin: 15px 0px 10px;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 10px;
}

button.btn.location-button span.locat-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.related-products .product-boxes_inner,
.prodcuts_listing_colright .product-boxes_inner {
    min-height: unset;
}

.d-flexbutton {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
}

.statuspending {
    background: #ff00001a;
    padding: 10px 12px;
    border-radius: 2px;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 3px;
    color: red;
    border: 0px;
    font-weight: 600;
}

.statusaprprove {
    background: #00cf002e;
    padding: 10px 12px;
    border-radius: 2px;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 3px;
    color: green;
    border: 0px;
    font-weight: 600;
}

.rejectaprprove {
    background: #ff00001a;
    padding: 10px 12px;
    border-radius: 2px;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 3px;
    color: red;
    border: 0px;
    font-weight: 600;
}

.prodcuts_Details_right .proDescription {
    border-radius: 12px;
}

.time-slot-selector {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.slot-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid var(--theme-color);
    border-radius: 5px;
    color: var(--theme-color);
    transition: all 0.3s ease;
    user-select: none;
}

.slot-button.active {
    background-color: var(--theme-color);
    color: #fff;
    border-color: var(--theme-color);
}

.slot-button.disabled {
    background-color: #ccc;
    color: #666;
    border-color: #ccc;
    cursor: not-allowed;
}

.slot-button:hover:not(.disabled):not(.active) {
    background-color: #f0f0f0;
}

.radio-input {
    display: none;
    /* Hide the default radio button */
}

.reviewpage {
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, .12);
    border-radius: 5px;
    padding: 20px 20px 30px;
}

.reviewpageinner {
    display: flex;
    column-gap: 12px;
}

.reviewpageinner img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 100px;
}

.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    box-shadow: rgb(230, 235, 241) 0px 1px 3px 0px;
    transition: box-shadow 150ms;
}

.reviewpageinner h4, .reviewpageinner p {
    line-height: 27px;
    letter-spacing: 0;
    text-align: left;
}

.reviewpage .pera {
    margin-top: 10px;
}

.nn_map_details {
    margin: 70px 0px;
}

.autocomplete-dropdown-container {
    white-space: break-spaces;
    position: absolute;
    top: 100px;
    width: 100%;
    left: 0px;
}


.search-blockpopup ul li {
    color: #575757 !important;
    font-size: 14px !important;
    padding: 8px 0px !important;
    display: block !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    margin-bottom: 0px !important;
}

.location_input input {
    width: 100%;
}


.order_succes_page {
    padding: 140px 0 60px;
}

.order_succes_page h1 {
    font-size: 26px;
    font-weight: 600;
    margin: 0px;
    color: #77b43f !important;
}

.amount_description li {
    border-bottom: 1px solid #eaeaec;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;


}

.amount_price {
    font-size: 16px;
    font-weight: 600;
}


.cart_items_details_box {
    padding: 15px;
    border: 1px solid #dddddd;
    border-radius: 10px;
}

.cart_items_details_box .cart_product_box:last-child {
    margin-bottom: 0px;
    border-bottom: 0px;
    padding-bottom: 0px;
}

.cart_items_details_box .cart_product_box {
    margin: 0px;
    margin-bottom: 20px;
    display: flex;
    gap: 15px;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
    margin-top: 15px;
    position: relative;
}


.cart_product_image {
    border: 1px solid #dfdfdf;
    display: inline-block;
    border-radius: 6px;
}


.cart_product_details {
    width: calc(100% - 165px);
}

.cart_product_box h3 {
    font-size: 14px;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}

.cart_product_box p {
    font-size: 14px;
    margin: 0px;
}

.cart_items_details_box .cart_product_image img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}


.box_order {

    border: 1px solid #dbdbdb;
    padding: 20px;
    border-radius: 10px;
}



.icon_dash {
    background-color: white;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    font-size: 21px;
    justify-content: center;
}

.icon_dash i {
    color: #114cc2;
}

@media screen and (max-width: 1450px) {

    .my_count_name p {
        font-size: 12px;
    }

    .count_box p {

        margin: 0px;
        font-size: 18px;
    }

    .icon_dash {

        width: 28px;
        height: 28px;
        font-size: 13px;

    }

}


.my_count_name p {
    margin-bottom: 0px;
}
.main_outer  .dropZoneDefault{
text-align: center;
}
.main_outer   .dz-clickable.fx_img.nn_selling_img button {
    padding: 2px 5px 6px;
}
.input-group-text {
    background-color: transparent;
    padding: 0px;
    border: none;
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: 99;
}

.StripeElement {
    background: #edf2f7;
    border: 1px solid #cecece;
    border-radius: 10px;
    padding: 20px;
    height: unset;
}
.shippingbox-details:last-child{
    margin-bottom: 0px;
}
.shippingbox-details{
    display: flex;
    align-items: center;
    border: 1px solid #e5e5e5;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    justify-content: space-between;
}
.shippingbox-details img{
    width: 50px;
    height: auto;
} 
 .slide-pane__content  .shippingbox-details label{
    display: flex;
    width: 100%;
}
.shippingbox-details p{
    margin-bottom: 0px;
    line-height: 1.42857;
    font-size: 16px;
    display: block;
    color: #000;
}
.notificationbost_msg.scrollable {
    height: 298px;
    overflow: auto;
}
.dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
}